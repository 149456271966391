body {
	font-family: 'Marcellus', serif;
	background-color: #F6F4EC;
	color: #545748;
	min-height:100vh;
	display:flex;
	#root {
		flex-grow: 1;
		display:flex;
		flex-direction: column;
	}
}